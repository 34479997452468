import * as React from 'react';
import { Translate } from '../../localization/index';

const iconEmail = require('../../assets/imgs/icon-email.png');
const iconTel = require('../../assets/imgs/icon-tel.png');
const ccMaster = require('../../assets/imgs/cc-master.png');
const ccVisa = require('../../assets/imgs/cc-visa.png');

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="footer-left">
            <div className="customer-care">
              <h6><Translate id="customer-care" defaultMessage="Customer Care" /></h6>
              <div className="list-wrap">
                <div className="each">
                  <img src={iconTel} />
                  <a href="tel:+3197010208696">+31 970 1020 8696</a>
                </div>
                {/* <div className="each">
                  <img src={iconTel} />
                  <a href="tel:900751051">900 751 051 (<Translate id="spain" defaultMessage="Spain" />)</a>
                </div> */}
                <div className="each">
                  <img src={iconEmail} />
                  <a href="mailto:help@xracademy.online">help@xracademy.online </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="company-info">
              <h6>Sam Media B.V.</h6>
              <p>Van Diemenstraat 356, 1013 CR Amsterdam, The Netherlands<br/>
              <a href="https://www.sam-media.com" target="_blank">www.sam-media.com</a></p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="quick-links">
            <a className="each" href="https://portal.xracademy.online/privacy-policy" target="_blank">
              <Translate id="privacy-policy" defaultMessage="Privacy Policy" />
            </a>
            <a className="each" href="https://portal.xracademy.online/refund-policy" target="_blank">
              <Translate id="refund-policy" defaultMessage="Refund Policy" />
            </a>
            <a className="each" href="https://portal.xracademy.online/terms-and-conditions" target="_blank">
              <Translate id="terms-conditions" defaultMessage="Terms & Conditions" />
            </a>
            <a className="each" href="https://portal.xracademy.online/faq" target="_blank">
              <Translate id="faq" defaultMessage="FAQ" />
            </a>
          </div>
          <div className="credit-card-icon">
            <img src={ccMaster}  />
            <img src={ccVisa}  />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;