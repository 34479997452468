import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import * as React from 'react';
import Loader from '../Loader/index';
// import CreditCardInput from 'react-credit-card-input';
import { Input, InputType, Select, SelectType } from 'ouisys-component-library';
import { IUserDetails } from 'ouisys-engine/dist/reducers/creditCardFlow/CreditCardTypes';
import { MSISDNEntryFailure, MSISDNEntrySuccess } from 'ouisys-engine/dist/reducers/pinFlow/PinTypes';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import { useState } from 'react';
import { injectIntl, Translate } from '../../localization/index';

interface UserDetailsEntryStepProps {
  rds: RDS.RemoteDataState<MSISDNEntryFailure, MSISDNEntrySuccess>;
  onEnd: (userDetails: IUserDetails) => void;
  locale: string;
  intl: any;
}
const UserDetailsEntryStep = ({ rds, onEnd, locale, intl }: UserDetailsEntryStepProps) => {
  const { visitor } = window.pac_analytics;
  const { rockmanId } = visitor;

  const [validFirstname, setValidFirstname] = useState(true);
  const [validLastname, setValidLastname] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validAddress, setValidAddress] = useState(true);
  const [validPostcode, setValidPostcode] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [validCountry, setValidCountry] = useState(true);
  const [validRockmanid, setValidRockmanid] = useState(true);
  const [validServiceid, setValidServiceid] = useState(true);
  const [validExpirationYear, setValidExpirationYear] = useState(true);
  const [validExpirationMonths, setValidExpirationMonths] = useState(true);
  const [validCvv, setValidCvv] = useState(true);
  const [validCcnumber, setValidCc_number] = useState(true);
  const [validCcholder, setValidCcHolder] = useState(true);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('ES');
  const [rockmanid, setRockmanid] = useState('');
  const [serviceid, setServiceid] = useState('');
  const [cc_number, setCc_number] = useState('');
  const [cc_holder, setCc_holder] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [expirationMonths, setExpirationMonths] = useState('');
  const [cvv, setCvv] = useState('');

  const validateFirstname = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setFirstname(value);
    setValidFirstname(valid);
    return valid;
  };
  const validateLastname = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setLastname(value);
    setValidLastname(valid);
    return valid;
  };
  const validateEmail = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setEmail(value);
    setValidEmail(valid);
    return valid;
  };
  const validateAddress = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setAddress(value);
    setValidAddress(valid);
    return valid;
  };
  const validatePostcode = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setPostcode(value);
    setValidPostcode(valid);
    return valid;
  };
  const validateCity = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCity(value);
    setValidCity(valid);
    return valid;
  };
  const validateCountry = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCountry(value);
    setValidCountry(valid);
    return valid;
  };
  // const validateRockmanid = (ev) => {
  //   ev.persist();
  //   const value = ev.target.value;
  //   const valid = value.length > 0;
  //   setRockmanid(value);
  //   setValidRockmanid(valid);
  //   return valid;
  // };
  const validateServiceid = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setServiceid(value);
    setValidServiceid(valid);
    return valid;
  };
  const validateCcnumber = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCc_number(value);
    setValidCc_number(valid);
    return valid;
  };
  const validateCcholder = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCc_holder(value);
    setValidCcHolder(valid);
    return valid;
  };
  const validateExpirationYear = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value < 3000;
    setExpirationYear(value);
    setValidExpirationYear(valid);
    return valid;
  };

  const validateExpirationMonths = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value < 13;
    setExpirationMonths(value);
    setValidExpirationMonths(valid);
    return valid;
  };

  const validateCvv = (ev) => {
    ev.persist();
    const value = ev.target.value;
    const valid = value.length > 0;
    setCvv(value);
    setValidCvv(valid);
    return valid;
  };

  //limiting using input for creidt card number (front end validation)
  // rewrite this to an array of allowed key codes
  // const allowedKeyCodes = {
  //   Backspace: 'Backspace',
  //   Tab: 'Tab',
  //   Escape: 'Escape',
  //   Enter: 'Enter',
  //   ArrowLeft: 'ArrowLeft',
  //   ArrowRight: 'ArrowRight'
  // };


  const allowedKeyCodes = ["Backspace", "Tab", "Escape", "Enter", "ArrowLeft", "ArrowRight"]

  const isAllowedKey = (key) => allowedKeyCodes.includes(key);

  const isAllowedCharacter = (char) => /[0-9\s-]/.test(char);

  const handlekeyDown = (event) => {
    const keyPressed = event.key;
    console.log('keyPressed', keyPressed);
    if (!isAllowedKey(keyPressed) && !isAllowedCharacter(keyPressed)) {
      event.preventDefault();
    }
  };

  const defaultMessages = {
    firstname: {
      id: 'firstname',
      defaultMessage: 'First Name'
    },
    lastname: {
      id: 'lastname',
      defaultMessage: 'Last Name'
    },
    email: {
      id: 'email',
      defaultMessage: 'Email'
    },
    address: {
      id: 'address',
      defaultMessage: 'Address'
    },
    postcode: {
      id: 'postcode',
      defaultMessage: 'Postcode'
    },
    city: {
      id: 'city',
      defaultMessage: 'City'
    },
    country: {
      id: 'country_code',
      defaultMessage: 'Country'
    },
    rockmanid: {
      id: 'rockman_id',
      defaultMessage: 'Rockman ID'
    },
    serviceid: {
      id: 'service_id',
      defaultMessage: 'Service ID'
    },

    required: {
      id: 'required',
      defaultMessage: 'is required'
    },

    cc_number: {
      id: 'cardNumber',
      defaultMessage: 'Credit Card Number'
    },

    cc_holder: {
      id: 'cardHolder',
      defaultMessage: 'Credit Card Holder Name'
    },

    expirationYear: {
      id: 'expYear',
      defaultMessage: 'Expiry Year'
    },

    expirationMonths: {
      id: 'expMonth',
      defaultMessage: 'Expiry Month'
    },

    cvv: {
      id: 'cvv',
      defaultMessage: 'CVV Code'
    }
  };

  return (
      <form
        autoComplete="on"
        onSubmit={(ev) => {
          ev.preventDefault();

          onEnd({
            first_name: firstname,
            last_name: lastname,
            email,
            address,
            postcode,
            city,
            country_code: country,
            cc_number,
            cc_holder,
            cc_exp_month: expirationMonths,
            cc_exp_year: expirationYear,
            cvv
          });
        }}
      >
        {RDS.WhenLoading(null, () => (
          <div>
            <Loader />
          </div>
        ))(rds)}
        {RDS.WhenFailure(null, (err: MSISDNEntryFailure) => {
          return (
            <div className="errorMsg">
              <Translate id={err.errorType} />
              <br />
              {err.errorType == 'AlreadySubscribed' && (
                <a target="_blank" href={err.productUrl}>
                  <Translate id="goToProductLabel" defaultMessage="Go to Product" />
                </a>
              )}
            </div>
          );
        })(rds)}
        <div className="form-group">
          <label className="form-label">
            <Translate id="firstname" defaultMessage="First Name" />
          </label>
          <Input
            id={defaultMessages.firstname.id}
            value={firstname}
            onBlur={validateFirstname}
            onChange={validateFirstname}
            required
            // label="Name"
            placeholder={intl.formatMessage(defaultMessages.firstname)}
            className="form-control"
            error={
              !validFirstname &&
              intl.formatMessage(defaultMessages.firstname) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Text}
            autoComplete="cc-given-name"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <Translate id="lastname" defaultMessage="Last Name" />
          </label>
          <Input
            id={defaultMessages.lastname.id}
            value={lastname}
            onBlur={validateLastname}
            onChange={validateLastname}
            required
            // label="Name"
            placeholder={intl.formatMessage(defaultMessages.lastname)}
            className="form-control"
            error={
              !validLastname &&
              intl.formatMessage(defaultMessages.lastname) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Text}
            autoComplete="cc-family-name"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <Translate id="email" defaultMessage="Email" />
          </label>
          <Input
            id={defaultMessages.email.id}
            value={email}
            onBlur={validateEmail}
            onChange={validateEmail}
            required
            // label="Email"
            placeholder={intl.formatMessage(defaultMessages.email)}
            className="form-control"
            error={
              !validEmail &&
              intl.formatMessage(defaultMessages.email) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Email}
            autoComplete="email"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <Translate id="address" defaultMessage="Address" />
          </label>
          <Input
            id={defaultMessages.address.id}
            value={address}
            onBlur={validateAddress}
            onChange={validateAddress}
            required
            // label="Address"
            placeholder={intl.formatMessage(defaultMessages.address)}
            className="form-control"
            error={
              !validAddress &&
              intl.formatMessage(defaultMessages.address) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Text}
            autoComplete="billing street-address"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <Translate id="postcode" defaultMessage="Postcode" />
          </label>
          <Input
            id={defaultMessages.postcode.id}
            value={postcode}
            onBlur={validatePostcode}
            onChange={validatePostcode}
            required
            // label="Postcode"
            placeholder={intl.formatMessage(defaultMessages.postcode)}
            className="form-control"
            error={
              !validPostcode &&
              intl.formatMessage(defaultMessages.postcode) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Text}
            autoComplete="billing postal-code"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <Translate id="city" defaultMessage="City" />
          </label>
          <Input
            id={defaultMessages.city.id}
            value={city}
            onBlur={validateCity}
            onChange={validateCity}
            required
            placeholder={intl.formatMessage(defaultMessages.city)}
            className="form-control"
            error={
              !validCity &&
              intl.formatMessage(defaultMessages.city) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Text}
            autoComplete="billing address-level2"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            <Translate id="country" defaultMessage="Country" />
          </label>
          <Select
            id={defaultMessages.country.id}
            value={country}
            onBlur={validateCountry}
            onChange={validateCountry}
            required
            // disabled
            // firstOptionText="Select Country"
            // label="Country"
            placeholder={intl.formatMessage(defaultMessages.country)}
            className="form-control"
            error={
              !validCountry &&
              intl.formatMessage(defaultMessages.country) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={SelectType.Country}
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            <Translate id="cardNumber" defaultMessage="Card Number" />
          </label>
          <Input
            id={defaultMessages.cc_number.id}
            value={cc_number}
            onBlur={validateCcnumber}
            onChange={validateCcnumber}
            required
            // label="Postcode"
            placeholder={intl.formatMessage(defaultMessages.cc_number)}
            className="form-control"
            error={
              !validCcnumber &&
              intl.formatMessage(defaultMessages.cc_number) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Text}
            autoComplete="cc-number"
            onKeyDown={handlekeyDown}
            pattern="(?:\d[ -]?){16,19}"
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            <Translate id="cardHolder" defaultMessage="Card Holder" />
          </label>
          <Input
            id={defaultMessages.cc_holder.id}
            value={cc_holder}
            onBlur={validateCcholder}
            onChange={validateCcholder}
            required
            // label="Postcode"
            placeholder={intl.formatMessage(defaultMessages.cc_holder)}
            className="form-control"
            error={
              !validCcholder &&
              intl.formatMessage(defaultMessages.cc_holder) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Text}
            autoComplete="cc-name"
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            <Translate id="expMonth" defaultMessage="Expiry Month" />
          </label>
          <select
            id={defaultMessages.expirationMonths.id}
            value={expirationMonths}
            onBlur={validateExpirationMonths}
            onChange={validateExpirationMonths}
            required
            className="form-control"
            autoComplete="on"
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <option key={month} value={month.toString().padStart(2, '0')}>
                {month.toString().padStart(2, '0')}
              </option>
            ))}
          </select>
          {!validExpirationMonths && (
            <div className="error-message">
              {intl.formatMessage(defaultMessages.expirationMonths)} {intl.formatMessage(defaultMessages.required)}
            </div>
          )}
        </div>

        <div className="form-group">
          <label className="form-label">
            <Translate id="expYear" defaultMessage="Expiry Year" />
          </label>
          <select
            id={defaultMessages.expirationYear.id}
            value={expirationYear}
            onBlur={validateExpirationYear}
            onChange={validateExpirationYear}
            required
            className="form-control"
            autoComplete="on"
          >
            {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          {!validExpirationYear && (
            <div className="error-message">
              {intl.formatMessage(defaultMessages.expirationYear)} {intl.formatMessage(defaultMessages.required)}
            </div>
          )}
        </div>

        <div className="form-group">
          <label className="form-label">CVV</label>
          <Input
            id={defaultMessages.cvv.id}
            value={cvv}
            onBlur={validateCvv}
            onChange={validateCvv}
            required
            // label="Postcode"
            placeholder={intl.formatMessage(defaultMessages.cvv)}
            className="form-control"
            error={
              !validCvv && intl.formatMessage(defaultMessages.cvv) + ' ' + intl.formatMessage(defaultMessages.required)
            }
            type={InputType.Number}
            autoComplete="cc-cvv"
          />
        </div>
        {/*         
        <div className="form-group">
        <Input
            id={defaultMessages.rockmanid.id}
            value={rockmanId}
            onBlur={validateRockmanid}
            onChange={validateRockmanid}
            required
            type={InputType.Text}
          />
        </div> */}
        {/* <div className="form-group">
        <Input
            id={defaultMessages.serviceid.id}
            value={serviceid}
            onBlur={validateServiceid}
            onChange={validateServiceid}
            required
            type={InputType.Text}
          />
        </div> */}

        <div className="form-group for-checkbox">
          <label className="checkbox-wrap">
            <input type="checkbox" required />
            <p>
              <Translate
                id="regCheckbox1"
                defaultMessage="By signing up for our website, you confirm your acceptance of the " />
              <a href="https://portal.xracademy.online/terms-and-conditions" target="_blank">
                <Translate id="terms-conditions" defaultMessage="Terms & Conditions" />
              </a>
              &nbsp;,&nbsp;&nbsp;
              <a className="each" href="https://portal.xracademy.online/privacy-policy" target="_blank">
                <Translate id="privacy-policy" defaultMessage="Privacy Policy" />
              </a>
              &nbsp;,&nbsp;&nbsp;
              <Translate
                id="regCheckbox2"
                defaultMessage="and voluntarily opt into our subscription service, ensuring you receive the full benefits of our platform"
               />
              .
            </p>
          </label>
        </div>
        <div className="form-group for-checkbox">
          <label className="checkbox-wrap">
            <input type="checkbox" required />
            <p>
              <Translate
                id="regCheckboxNew"
                defaultMessage="I confirm that I am authorized to use the provided payment method for this recurring billing agreement." />
            </p>
          </label>
        </div>
        <button
          id="user-details-submit-button"
          type="submit"
          className="btn dcbp btn-red"
          onClick={() => {
            tracker.advancedInFlow('Flow', 'form-submitted');
          }}
        >
          <Translate id="startTrial" defaultMessage="start trial" />
        </button>
      </form>
  );
};

export default injectIntl(UserDetailsEntryStep);
