// dependencies
import React, { lazy, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Disclaimers, PricePoint, defineMessages, TopLegals, LanguageSwitcher } from 'ouisys-component-library';
import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import strategy from 'ouisys-engine/dist/flows/strategy';
import { identifyStrategy, StrategyFlowActionMaps } from 'ouisys-engine/dist/reducers/strategy';
import {
  IDENTIFYMSISDNEntryFailure,
  IDENTIFYMSISDNEntrySuccess,
  IIdenyifyFlowByMsisdnSumbitNextData
} from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import store from 'ouisys-engine/dist/store';
import { IApplicationState } from 'ouisys-engine/dist/store/reducers';
import { setOnLoadUtilities } from 'ouisys-engine/dist/utilities';
import { getConfig } from 'ouisys-phone-input/dist/common/PhoneInput';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';

// local
import Loader from './components/Loader';
import Footer from './components/Footer';
import OperatorSelection from './components/OperatorSelection';
import PhoneNumberEntryStep from './components/PhoneNumberEntryStep';
// import SwitchLang from './components/SwitchLang';
import PaymentStatus from './components/PaymentStatus';
import { legals, Translate, TranslationProvider } from './localization/index';
import searchToObject from './utils/searchToObject';
import PreLander from './components/PreLander';
import Click2smsFlow from './flows/Click2smsFlow';
import MoFlow from './flows/MoFlow';
import MoRedirFlow from './flows/MoRedirFlow';
import OneClickFlow from './flows/OneClickFlow';
import PinFlow from './flows/PinFlow';
import UssdFlow from './flows/UssdFlow';
import CreditCardFlow from './flows/CreditCardFlow';
import './Bloxz.scss';
import './Root.scss';
import './Style.scss';


// error messages messages which might come from backend
const defaultMessages = defineMessages({
  InvalidMSISDN: {
    id: 'InvalidMSISDN',
    defaultMessage: 'This mobile number is incorrect, try again'
  },
  AlreadySubscribed: {
    id: 'AlreadySubscribed',
    defaultMessage: 'You are already a subscriber!'
  },
  UnknownError: {
    id: 'UnknownError',
    defaultMessage: 'An unknown error occurred.'
  },
  InvalidPIN: {
    id: 'InvalidPIN',
    defaultMessage: 'Please enter the correct PIN that you received on your phone'
  },
  SessionBlocked: {
    id: 'SessionBlocked',
    defaultMessage: 'Session Blocked!'
  },
  TooEarly: {
    id: 'TooEarly',
    defaultMessage: 'You must first submit a mobile number'
  },
  didnt_receive_pin_yet: {
    id: 'didnt_receive_pin_yet',
    defaultMessage: "Didn't receive PIN yet?"
  }
});

interface HOCProps {}

const PRIMARY_LOCALE = 'en';
const PRIMARY_LOCALE_TEXT = 'English';

const Root = ({
}: HOCProps) => {
  const [locale, setLocale] = useState(PRIMARY_LOCALE);
  const [operator, setOperator] = useState<string | null>(null);
  const [msisdn, setMsisdn] = useState<string>(getConfig(process.env.country).commonPrefix);
  const [skipPrelander, setPrelander] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const dispatch = useDispatch();
  const { currentState } = useSelector((state: IApplicationState) => state.strategy);
  const urlParams = searchToObject();
  const [isModal, setModal] = useState(false);
  const [isOverlay, setOverlay] = useState(false)
  const { d_theme, d_locale } = urlParams as { d_theme: string; d_locale: string; };
  
  //img library
  const logoH = require('./assets/imgs/logo.svg');
  const logoV = require('./assets/imgs/logo_v.svg');
  const logoB = require('./assets/imgs/logo_b.svg');
  
  const cat01 = require('./assets/imgs/cat01.png');
  const cat02 = require('./assets/imgs/cat02.png');
  const cat03 = require('./assets/imgs/cat03.png');
  const cat04 = require('./assets/imgs/cat04.png');
  const cat05 = require('./assets/imgs/cat05.png');
  const cat06 = require('./assets/imgs/cat06.png');

  const imgShowcase = require('./assets/imgs/showcase.png');
  

  // show or hide pre-lander
  const checkSkipPrelander = () => {
    if (urlParams['skip-prelander']) {
      setPrelander(urlParams['skip-prelander'] === 'true');
    }
  };

  // Show or hide thank you
  const checkPaymentStatus = () => {
    if (urlParams['payment-status']) {
      setPaymentStatus(urlParams['payment-status'] === 'true');
    }
  };


  const switchLang = (lang: string) => {
    setLocale(lang);
    if (typeof window !== 'undefined') {
      window.languageCode = lang;
      document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    }
  };

  // const divFocus = () => {
  //   // show the overlay
  //   setOverlay(true);
  //   if (typeof window !== 'undefined') {
  //     // reset bx-holder class
  //     if (document?.querySelector('.bx-holder'))
  //       document.querySelector('.bx-holder').className = 'bx-holder';
  //     // add share-horizontal on divFocus
  //     if (document?.querySelector('#divFocus'))
  //       document.querySelector('#divFocus').className = 'shake-horizontal';
  //   }
  // };

  const [isActive, setIsActive] = useState(false);

  const handleClick = () =>  {
    setIsActive(true)
    document.documentElement.classList.add("pp-active");
  };
  const handleCloseClick = () => {
    setIsActive(false);
    document.documentElement.classList.remove("pp-active");
  };

  const identifyFlowByMsisdnInterface = (
    rds: RDS.RemoteDataState<IDENTIFYMSISDNEntryFailure, IDENTIFYMSISDNEntrySuccess>,
    nextData: IIdenyifyFlowByMsisdnSumbitNextData
  ) => {
    const { nextAction } = nextData;
    return (
      <PhoneNumberEntryStep
        msisdn={msisdn}
        rds={rds}
        onEnd={(msisdn) => {
          setMsisdn(msisdn);
          dispatch(StrategyFlowActionMaps[nextAction](msisdn));
        }}
        locale={locale}
      />
    );
  };

  useEffect(() => {
    dispatch(identifyStrategy());
    checkSkipPrelander();
    checkPaymentStatus();

    //for zendesk widget
    if(typeof window !== "undefined"){
      
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=e44e1419-abf3-4f96-bfff-b8e6c0df2bc0';
      script.async = true;
      document.head.appendChild(script);
    }
    //zendesk widget end

    if (typeof window != "undefined") {
      window.languageCode = locale;
      document.getElementsByTagName('html')[0].setAttribute("lang", locale)
    }
    //Set locale based on url designer para
    if (d_locale) {
      switchLang(d_locale)
    }

    setOnLoadUtilities((currentState as any)?.result?.config);
  }, []);

  return (
    <TranslationProvider locale={locale}>
      <div className={`outer-wrap bx-${locale}`}>
        <header className="bx-header">
          <div className="container">
            {/* service-logo */}
            <img src={logoH} className="brand for-desktop"/>
            <img src={logoV} className="brand for-mobile"/>



            <LanguageSwitcher
              locale={locale}
              switchLang={switchLang}
              options={[

                //! if you're commenting back in some languages, 
                //! make sure the translation files are up to date with the changes made to en.json
                { value: 'en', label: 'English' },
                // { value: 'ar', label: 'عربى' },
                // { value: 'am', label: 'ኢትዮጵያ' },  //Ethiopian
                // { value: 'cz', label: 'Česká republika' }, //Czech Republic
                { value: 'da', label: 'Dansk' }, //Danish
                { value: 'de', label: 'Deutschland' }, //German
                { value: 'es', label: 'España' }, //Spanish
                { value: 'fr', label: 'France' }, //French
                // { value: 'id', label: 'Indonesia' }, //Indonesian
                { value: 'it', label: 'Italiano' }, //Italian
                // { value: 'nl', label: 'Nederland' }, //Dutch
                // { value: 'pl', label: 'Polska' }, //Polish
                // { value: 'ru', label: 'Россия' }, //Russian
                // { value: 'sr', label: 'Србија' }, //Serbian
                // { value: 'th', label: 'ไทย' }, //Thai
                // { value: 'vi', label: 'Việt Nam' } //Vietnamese
              ]}
              className="switchLang"
              tracker={tracker}
            />

            <a className="btn" href="https://login.xracademy.online/7OJpLo" target="_blank" onClick={() => {tracker.advancedInFlow("Flow","Login");}}>
              <Translate id="login" defaultMessage="Login" />
            </a>
          </div>
        </header>
        {/* Payment Status */}
        {
          paymentStatus &&
          <PaymentStatus  />
        }

        <main className={`bx-container bx-modal-${isModal} bx-overlay-${isOverlay} ${paymentStatus == true ? "hide" : ""}`}>

          <section className="sec1">
            <div className="container">
              <h1><Translate id="mainTitle" defaultMessage="Meet the future of education" /></h1>
              <p><Translate id="pageIntro" defaultMessage="Elevate your learning experience with the most advanced AR and VR technologies. Futuristic education platform no longer belong to the future. Be a part of the immersive learning adventures now." /></p>
              <button  onClick={() => {handleClick(); tracker.advancedInPreFlow("startTrial-clicked");}}><Translate id="startTrial" defaultMessage="start trial" /></button>
              <div className="price-point">
              {/* 24  <Translate id="freeTrial" defaultMessage="Free Trial" /><br /> */}
                {/* €3,99 / <Translate id="daysTrial" defaultMessage="3 Days Trial" /> */}
                €0,99 <Translate id="trialPrice" defaultMessage="/ 24 hour trial" /><br />
                €49,99 / <Translate id="monthlySubscription" defaultMessage="Monthly subscription" /><br />
                <Translate id="trialTC" defaultMessage="Trial for eligible new members only • Recurring billing • Cancel at any time" />
              </div>
              <div className="aniScroll"><div className="mousey"><div className="scroller"></div></div></div>
            </div>
          </section>

          <section className="sec2">
            <div className="container">
              <h2><Translate id="sec2Title" defaultMessage="Transforming the way education is delivered and consumed globally" /></h2>
              <p><Translate id="sec2Intro" defaultMessage="Explore destinations around the world and faraway planets across the universe from the comfort of your home." /></p>
              <div className="catWrap">
                <div className="each">
                  <img src={cat01} />
                  <h5 className="catName">
                    <Translate id="cat01" defaultMessage="Human body" />
                  </h5>
                </div>
                <div className="each">
                  <img src={cat02} />
                  <h5 className="catName">
                    <Translate id="cat02" defaultMessage="Kingdom Of Living Things" />
                  </h5>
                </div>
                <div className="each">
                  <img src={cat03} />
                  <h5 className="catName">
                    <Translate id="cat03" defaultMessage="Space & Science" />
                  </h5>
                </div>
                <div className="each">
                  <img src={cat04} />
                  <h5 className="catName">
                    <Translate id="cat04" defaultMessage="Art" />
                  </h5>
                </div>
                <div className="each">
                  <img src={cat05} />
                  <h5 className="catName">
                    <Translate id="cat05" defaultMessage="Cultural Heritage & History" />
                  </h5>
                </div>
                <div className="each">
                  <img src={cat06} />
                  <h5 className="catName">
                    <Translate id="cat06" defaultMessage="Archeology" />
                  </h5>
                </div>
              </div>
            </div>
          </section>

          <section className="sec3">
            <div className="container">
              <h2><Translate id="sec3Title" defaultMessage="Compatible with all VR gadgets" /></h2>
              <p><Translate id="sec3Intro1" defaultMessage="You can learn and engage without the gadgets as well." /></p>
              <p><Translate id="sec3Intro2" defaultMessage="All you need to start your learning experience is a smartphone or a computer." /></p>
              <img src={imgShowcase} />
            </div>
          </section>

          <section className="sec4">
            <div className="container">
              <h2><Translate id="sec4Title" defaultMessage="The Future is NOW" /></h2>
              <img src={logoH} />
              <p><Translate id="sec4Intro" defaultMessage="Be a part of the immersive learning adventures now." /></p>
              <button  onClick={() => {handleClick(); tracker.advancedInPreFlow("startTrial-clicked");}}><Translate id="startTrial" defaultMessage="start trial" /></button>
            </div>
          </section>

        </main>

        <Footer />

        <section className={`payment-page ${ isActive ? "is-visible" : ""}` } >
          <button className="pp-close" onClick={handleCloseClick} data-close>
            <svg xmlns="http://www.w3.org/2000/svg">
              <path id="Icon_material-close" d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)" />
            </svg>
          </button>
          <div className="pp-header">
            <div className="container">
              <img className="pp-logo" src={logoB} />
            </div>
          </div>
          <div className="container pp-wrap">
            <div className="pp-left">
              <h4><Translate id="registerTitle" defaultMessage="Finish your Registration" /></h4>
              
              <p>
                €0,99 <Translate id="trialPrice" defaultMessage="/ 24 hour trial" /><br />
                <Translate id="registerIntro" defaultMessage="The subscription will be automatically prolonged for €49,99 / monthly after the first period" /></p>
              {strategy({
                identifyFlowByMsisdn: (rds, nextData) =>
                  identifyFlowByMsisdnInterface(rds, nextData),
                identifyFlowByOperatorSelect: (data) => (
                  <OperatorSelection
                    setOperator={setOperator}
                    {...data}
                  />
                ),
                pinFlow: (nextData) => (
                  <PinFlow
                    nextData={nextData}
                    locale={locale}
                    msisdn={msisdn}
                    operator={operator}
                    setMsisdn={setMsisdn}
                  />
                ),
                moFlow: (nextData) => (
                  <MoFlow
                    nextData={nextData}
                    locale={locale}
                    msisdn={msisdn}
                    operator={operator}
                    setMsisdn={setMsisdn}
                  />
                ),
                moRedirFlow: (nextData) => (
                  <MoRedirFlow
                    nextData={nextData}
                    locale={locale}
                    msisdn={msisdn}
                    operator={operator}
                    setMsisdn={setMsisdn}
                  />
                ),
                oneClickFlow: (nextData) => (
                  <OneClickFlow
                    nextData={nextData}
                    locale={locale}
                    operator={operator}
                  />
                ),
                click2smsFlow: (nextData) => (
                  <Click2smsFlow
                    nextData={nextData}
                    locale={locale}
                    operator={operator}
                  />
                ),
                ussdFlow: (nextData) => (
                  <UssdFlow
                    nextData={nextData}
                    locale={locale}
                    msisdn={msisdn}
                    operator={operator}
                    setMsisdn={setMsisdn}
                  />
                ),
                creditCardFlow: (nextData) =>
                  <CreditCardFlow
                    nextData={nextData}
                    locale={locale}
                  />
              })(currentState)}

              {RDS.WhenLoading(null, () => (
                <div className="loading-wrapper">
                  <Loader />
                  <div>
                    <Translate id="loadingLabel" defaultMessage="Please wait..." />
                  </div>
                </div>
              ))(currentState?.result as RDS.RemoteDataState<unknown, unknown>)}
            </div>
            <div className="pp-right">
              <div className="each">
                <h4><Translate id="regTerms-title-1" defaultMessage="Safe and Secure" /></h4>
                <p><Translate id="regTerms-p-1" defaultMessage="All user data are protected in the service.  Your information is safe and secure as we protect our member’s information by using the best security measures possible. We will not sell or rent your private information to third parties, we value your privacy." /></p>
              </div>
              <div className="each">
                <h4><Translate id="regTerms-title-2" defaultMessage="Why do we need your billing information?" /></h4>
                <p><Translate id="regTerms-p-2" defaultMessage="Because we are only licensed to distribute our content to certain countries, we ask that you verify your mailing address by providing us with a valid credit card number. No charges will appear on your credit card statement unless you upgrade to a Premium Membership or make a purchase." /></p>
              </div>
              <div className="each">
                <h4><Translate id="regTerms-title-3" defaultMessage="Sign up today, here’s why:" /></h4>
                <li><Translate id="regTerms-li-1" defaultMessage="Exclusive content" /></li>
                <li><Translate id="regTerms-li-2" defaultMessage="Ad-free!" /></li>
              </div>
              <div className="each">
                <h4><Translate id="regTerms-title-4" defaultMessage="Get access now!" /></h4>
                <p><Translate id="regTerms-p-3" defaultMessage="We make sure to provide our members with a detailed transaction history so that they know what they are paying for. Credit card information is required to facilitate future purchases only." /></p>
              </div>
              <div className="each">
                <h4><Translate id="regTerms-title-5" defaultMessage="Cancellation Policy" /></h4>
                <p><Translate id="regTerms-p-5" defaultMessage="You can cancel your membership at any time by contacting our Customer Care Department free of charge." /></p>
                <p><Translate id="regTerms-p2-5" defaultMessage="The charge for your subscription to XR Academy will appear on your credit card statement as" /> xracademy.online +31 970 1020 8696</p>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </div>
    </TranslationProvider>
  );
};

const R = () => (
  <Provider store={store}>
    <Root />
  </Provider>
);

export default R;
