import * as React from 'react';
import { injectIntl, Translate } from '../../localization/index';
import { product } from 'ramda';



const tryAgain = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
  window.location.reload();
};


const PaymentStatus = () => {


const urlParams = new URLSearchParams(window.location.search);

// Read a specific parameter
let userStatus = urlParams.get('user-status');
let productUrl = urlParams.get('product-url');

return (
<section className="tq-page">
  <div className="container">
    <div className="box">
    {
      //if true
      userStatus === 'paymentSuccess' ?
        <div className="cctqbox">
          <h3><Translate id="congratsTitle" defaultMessage="Congratulations!" /></h3>
          <p>
            <Translate id="congratsText" defaultMessage="You've Successfully Subscribed to " />MovioTV
          </p>
          <a className="btn btn-red" href={`https://${productUrl}`} target="_blank">
            <Translate id="portalButton" defaultMessage="Explore Now" />
          </a>
        </div>
        //else if
        : userStatus === 'alreadySubscribed' ?
          <div className="cctqbox">
            <h3><Translate id="AlreadySubscribed" defaultMessage="You are already a subscriber!" /></h3>
            <a className="btn btn-red" href={`https://${productUrl}`} target="_blank">
              <Translate id="portalButton2" defaultMessage="Go to portal" />
            </a>
          </div>
          //else
          : <div className="cctqbox">
              <h3><Translate id="paymentFailTitle" defaultMessage="Payment Error" /></h3>
              <p>
                <Translate id="paymentFailText" defaultMessage="Payment was unsuccessful. Your credit card was not charge. Please try again!" />
              </p>
              <button className="btn btn-red" onClick={tryAgain}>
                <Translate id="tryagain" defaultMessage="Try Again" />
              </button>
            </div>

    }
    </div>
  </div>
</section>
)
 
};

export default injectIntl(PaymentStatus);
